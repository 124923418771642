import {createRouter, createWebHistory, RouteRecordRaw} from 'vue-router'
import store from '@/store'
// import NotFound from '@/views/Page404.vue'
import {inject} from 'vue'

const ProjectTopTabs = () => import(/* webpackChunkName: "project" */ '@/views/Project/ProjectTopTabs')

const TicketScreen = () => import(/* webpackChunkName: "ticket" */ '@/views/Ticket/TicketScreen')

const BoardViewScreen = () => import(/* webpackChunkName: "boardview" */ '@/views/Project/BoardView/BoardViewScreen')

const ListViewScreen = () => import(/* webpackChunkName: "listview" */ '@/views/Project/ListView/ListViewScreen')

// const ReportsScreen = () => import(/* webpackChunkName: "reports" */ '@/views/Project/Reports/ReportsScreen')
// const ReportsGeneral = () => import(/* webpackChunkName: "reports" */ '@/views/Project/Reports/ReportsGeneral')
// const ReportsPerformance = () => import(/* webpackChunkName: "reports" */ '@/views/Project/Reports/ReportsPerformance')

const ReportGeneral = () => import(/* webpackChunkName: "reports" */ '@/views/Project/Reports/ReportGeneral.vue')

//const SettingsScreen = () => import(/* webpackChunkName: "settings" */ '@/views/Project/Settings/SettingsScreen.vue')
const SettingsProjectInfo = () => import(/* webpackChunkName: "projectinfo" */ '@/views/Project/Settings/SettingsProjectInfo.vue')
//const SettingsWorkflow = () => import(/* webpackChunkName: "settings" */ '@/views/Project/Settings/SettingsWorkflow')
const SettingsWorkflowDesigner = () => import(/* webpackChunkName: "workflowdesigner" */ '@/views/Project/Settings/SettingsWorkflowDesigner.vue')
const SettingsProjectTeam = () => import(/* webpackChunkName: "projectteam" */ '@/views/Project/Settings/SettingsProjectTeam.vue')
const SettingsTicketDesigner = () => import(/* webpackChunkName: "ticketdesigner" */ '@/views/Project/Settings/SettingsTicketDesigner.vue')

const PeopleScreen = () => import(/* webpackChunkName: "people" */ '@/views/People/PeopleScreen')
const PeopleTopTabs = () => import(/* webpackChunkName: "people" */ '@/views/People/PeopleTopTabs.vue')

const DashboardScreen = () => import(/* webpackChunkName: "dashboard" */ '@/views/Dashboard/DashboardScreen')
const DashboardTopTabs = () => import(/* webpackChunkName: "dashboard" */ '@/views/Dashboard/DashboardTopTabs')

const AutomationScreen = () => import(/* webpackChunkName: "automation" */ '@/views/Project/Automation/AutomationScreen.vue')

const getGlobalProps = (route) => {
  const globalProps = {}
  const isDebugMode = !!inject('isDebugMode')
  console.log('router isDebugMode', isDebugMode)

  if (isDebugMode) {
    Object.assign(globalProps, {
      serverParam: route.query.serverParam || '',
    })
  }
  return globalProps
}

const routes: Array<RouteRecordRaw> = [
  // {
  //   path: '/',
  //   redirect: '/project'
  // },
  // {
  //   path: '/index',
  //   redirect: '/project'
  // },

  {
    path: '/ticket/:id?',
    name: 'Ticket',
    props: {
      default: (route)=>{
        return Object.assign({
          ticketId: route.params.id || '',
          clone: route.query.clone || '',
          viewNoteId: route.query.view || '',
          nextPrev: route.query.np || '',
          project: route.query.project || '',
        }, getGlobalProps(route))
      },
      TopTabs: false,
    },
    components: {
      default: TicketScreen,
      TopTabs: ProjectTopTabs,
    },
    meta: {
      layout: 'main',
      isProjectSubRoute: true,
      requiresAuth: true
    },
    beforeEnter(to, from, next) {
      store.commit("setProjectTab", "ticket");
      next();
    }
  },

  {
    path: '/board/:projectId?',
    alias: '/',
    name: 'Board',
    components: {
      default: BoardViewScreen,
      TopTabs: ProjectTopTabs,
    },
    meta: {
      layout: 'main',
      isProjectSubRoute: true,
      requiresAuth: true
    },
    props: {default: (route)=>{
       return Object.assign({
         projectId: route.params.projectId,
         search: route.query.search || '',
         searchIn: route.query.searchin || '',
         requestedById: route.query.requested || '',
         assignedToId: route.query.assigned || '',
       }, getGlobalProps(route))
      },
      TopTabs: false,
    },
    beforeEnter(to, from, next) {
      store.commit('setProjectTab', 'board');
      next()
    }
  },

  {
    path: '/list/:projectId?',
    name: 'List',
    props: {
      default: (route)=>{
        return Object.assign({
          projectId: route.params.projectId || '',
          selectedItemId: route.query.ticket || '',
          order: route.query.order || '',
          reverse: route.query.reverse ? 1 : 0,
          pageCurrent: route.query.page ? parseFloat(route.query.page as string) : null,
          step: route.query.step || '0',
          search: route.query.search || '',
          searchIn: route.query.searchin || '',
          requestedById: route.query.requested || '',
          assignedToId: route.query.assigned || '',
        }, getGlobalProps(route))
      },
      TopTabs: false,
    },
    components: {
      default: ListViewScreen,
      TopTabs: ProjectTopTabs,
    },
    meta: {
      layout: 'main',
      isProjectSubRoute: true,
      requiresAuth: true
    },
    beforeEnter(to, from, next) {
      store.commit('setProjectTab', 'listview')
      next();
    },
  },

  {
    path: '/project_info/:projectId?',
    name: 'ProjectInfo',
    components: {
      default: SettingsProjectInfo,
      TopTabs: ProjectTopTabs,
    },
    meta: {
      layout: 'main',
      isProjectSubRoute: true,
      isSettingsSubRoute: true,
      requiresAuth: true
    },
    props: {default: (route)=>{
        return Object.assign({
          projectId: route.params.projectId || '',
        }, getGlobalProps(route))
      },
      TopTabs: false,
    },
    beforeEnter(to, from, next) {
      store.commit('setProjectTab', 'settings')
      store.commit('setSettingsTab', 'ProjectInfo')
      next()
    }
  },

  {
    path: '/workflow/:projectId?',
    name: 'WorkflowDesigner',
    components: {
      default: SettingsWorkflowDesigner,
      TopTabs: ProjectTopTabs,
    },
    meta: {
      layout: 'main',
      isProjectSubRoute: true,
      isSettingsSubRoute: true,
      requiresAuth: true
    },
    props: {default: (route)=>{
        return Object.assign({
          projectId: route.params.projectId || '',
        }, getGlobalProps(route))
      },
      TopTabs: false,
    },
    beforeEnter(to, from, next) {
      store.commit('setProjectTab', 'settings')
      store.commit('setSettingsTab', 'WorkflowDesigner')
      next()
    }
  },

  {
    path: '/team/:projectId?',
    name: 'ProjectTeam',
    components: {
      default: SettingsProjectTeam,
      TopTabs: ProjectTopTabs,
    },
    meta: {
      layout: 'main',
      isProjectSubRoute: true,
      isSettingsSubRoute: true,
      requiresAuth: true
    },
    props: {default: (route)=>{
        return Object.assign({
          projectId: route.params.projectId || '',
        }, getGlobalProps(route))
      },
      TopTabs: false,
    },
    beforeEnter(to, from, next) {
      store.commit('setProjectTab', 'settings')
      store.commit('setSettingsTab', 'ProjectTeam')
      next()
    }
  },

  {
    path: '/ticket_form/:projectId?',
    name: 'TicketDesigner',
    components: {
      default: SettingsTicketDesigner,
      TopTabs: ProjectTopTabs,
    },
    meta: {
      layout: 'main',
      isProjectSubRoute: true,
      isSettingsSubRoute: true,
      requiresAuth: true
    },
    props: {default: (route)=>{
        return Object.assign({
          projectId: route.params.projectId || '',
        }, getGlobalProps(route))
      },
      TopTabs: false,
    },
    beforeEnter(to, from, next) {
      store.commit('setProjectTab', 'settings')
      store.commit('setSettingsTab', 'TicketDesigner')
      next()
    }
  },

  /*
    {
      path: '/settings/:projectId',
      name: 'Settings',
      props: {
        default: (route)=>{
          return Object.assign({
            projectId: route.params.projectId,
          }, getGlobalProps(route))
        },
        TopTabs: false,
      },
      components: {
        default: SettingsScreen,
        TopTabs: ProjectTopTabs,
      },
      meta: {
        layout: 'main',
        isProjectSubRoute: true,
        requiresAuth: true
      },
      children: [
        // {
        //   path: "info",
        //   alias: '',
        //   name: 'ProjectInfo',
        //   component: SettingsProjectInfo,
        //   meta: {
        //     isSettingsSubRoute: true
        //   }
        // },
        {
          path: "workflow",
          alias: '',
          name: 'WorkflowDesigner',
          component: SettingsWorkflow,
          meta: {
            isSettingsSubRoute: true
          }
        },
        {
          path: "team",
          alias: '',
          name: 'ProjectTeam',
          component: SettingsProjectTeam,
          meta: {
            isSettingsSubRoute: true
          }
        },
        {
          path: "ticketview",
          alias: '',
          name: 'TicketView',
          component: SettingsTicketDesigner,
          meta: {
            isSettingsSubRoute: true
          }
        }

      ],
      beforeEnter(to, from, next) {
        store.commit('setProjectTab', 'settings')
        next();
      },

    },
  */

  {
    path: '/report/:projectId?/general',
    alias: '/report/:projectId?',
    name: 'ReportGeneral',
    props: {
      default: (route)=>{
        const createdBy: string[] = []
        const assignedTo: string[] = []
        const stages: string[] = []
        const tags: string[] = []

        const createdByUrlParamValue = typeof route.query.a === "string" ? route.query.a.trim() : ''
        if (createdByUrlParamValue) {
          if (!createdByUrlParamValue.includes(',')) {
            createdBy.push(createdByUrlParamValue)
          } else {
            createdByUrlParamValue.split(',').forEach(str => {
              createdBy.push(str.trim())
            })
          }
        }
        console.log('router ReportGeneral createdBy', createdBy.length, JSON.stringify(createdBy))

        const assignedToUrlParamValue = typeof route.query.u === "string" ? route.query.u.trim() : ''
        if (assignedToUrlParamValue) {
          if (!assignedToUrlParamValue.includes(',')) {
            assignedTo.push(assignedToUrlParamValue)
          } else {
            assignedToUrlParamValue.split(',').forEach(str => {
              assignedTo.push(str.trim())
            })
          }
        }

        const stagesUrlParamValue = typeof route.query.s === "string" ? route.query.s.trim() : ''
        if (stagesUrlParamValue) {
          if (!stagesUrlParamValue.includes(',')) {
            stages.push(stagesUrlParamValue)
          } else {
            stagesUrlParamValue.split(',').forEach(str => {
              stages.push(str.trim())
            })
          }
        }

        const tagsUrlParamValue = typeof route.query.t === "string" ? route.query.t.trim() : ''
        if (tagsUrlParamValue) {
          if (!tagsUrlParamValue.includes(',')) {
            tags.push(tagsUrlParamValue)
          } else {
            tagsUrlParamValue.split(',').forEach(str => {
              tags.push(str.trim())
            })
          }
        }

        let headerType = 'stats'

        switch(route.query.h) {
          case 'n':
            headerType = 'none'
            break
          case 'c':
            headerType = 'cumulative'
            break
          case 'b':
            headerType = 'bottlenecks'
        }

        return Object.assign({
          projectId: route.params.projectId || '',
          //isCreated: route.query.x || '',
          color: route.query.c || '',
          createdBy: createdBy.length ? createdBy : undefined,
          assignedTo: assignedTo.length ? assignedTo : undefined,
          stagesFilter: stages.length ? stages : undefined,
          tagsFilter: tags.length ? tags : undefined,
          periodFilter: route.query.p || '',
          fromDate: route.query.b || '',
          toDate: route.query.e || '',
          archived: !!route.query.r,
          headerType,
          //header: route.query.h !== '0',
          // headerFilter: route.query.h,
          // chartFilter: route.query.g,
          notes: !!route.query.n,
          order: route.query.o || '',
          reverse: !!route.query.d,
          pageCurrent: route.query.page ? parseFloat(route.query.page as string) : null,
        }, getGlobalProps(route))
      },
      TopTabs: false,
    },

    components: {
      default: ReportGeneral,
      TopTabs: ProjectTopTabs,
    },
    meta: {
      layout: 'main',
      isProjectSubRoute: true,
      requiresAuth: true
    },
    beforeEnter(to, from, next) {
      store.commit('setProjectTab', 'reports')
      next()
    }
  },

/*
  {
    path: '/report/:projectId?',
    name: 'Report',
    props: {
      default: (route)=>{
        return Object.assign({
          projectId: route.params.projectId,
        }, getGlobalProps(route))
      },
      TopTabs: false,
    },

    components: {
      default: ReportsScreen,
      TopTabs: ProjectTopTabs,
    },
    meta: {
      layout: 'main',
      isProjectSubRoute: true,
      requiresAuth: true
    },
    children: [
      {
        path: 'general',
        alias: '',
        name: 'ReportGeneral',
        component: ReportsGeneral,
        meta: {
          isReportSubRoute: true
        }
      },

      {
        path: 'performance',
        name: 'ReportPerformance',
        component: ReportsPerformance,
        meta: {
          isReportSubRoute: true
        }
      },

    ],
    beforeEnter(to, from, next) {
      store.commit('setProjectTab', 'reports')
      next();
    },

  },
*/

  {
    path: '/dashboard',
    name: 'Dashboard',
    props: {
      default: (route)=>{
        console.log('Route Props changed route.query.reverse', route.query.reverse)
        return Object.assign({
          selectedItemId: route.query.ticket || '',
          order: route.query.order || '',
          reverse: route.query.reverse === '1' ? 1 : 0,
          pageCurrent: route.query.page ? parseFloat(route.query.page as string) : null,
          step: route.query.step || '0',
          search: route.query.search || '',
          searchIn: route.query.searchin || '',
          requestedById: route.query.requested || '',
          assignedToId: route.query.assigned || '',
        }, getGlobalProps(route))
      },
      TopTabs: false,
    },
    components: {
      default: DashboardScreen,
      TopTabs: DashboardTopTabs,
    },
    meta: {
      layout: 'main',
      requiresAuth: true
    }
  },
  {
    path: '/people/:userId?',
    name: 'People',
    props: {
      default: (route)=>{
        return Object.assign({
          selectedItemId: route.params.userId || '',
          order: route.query.order || '',
          reverse: route.query.reverse ? 1 : 0,
          pageCurrent: route.query.page ? parseFloat(route.query.page as string) : null,
          search: route.query.search || '',
        }, getGlobalProps(route))
     },
      TopTabs: false,
    },

    components: {
      default: PeopleScreen,
      TopTabs: PeopleTopTabs,
    },
    meta: {
      layout: 'main',
      requiresAuth: true
    },
  },
  {
    path: '/automation/:projectId?',
    name: 'Automation',
    props: {
      default: (route)=>{
        return Object.assign({
          projectId: route.params.projectId,
          viewRuleId: route.query.rule || '',
        }, getGlobalProps(route))
      },
      TopTabs: false,
    },
    components: {
      default: AutomationScreen,
      TopTabs: ProjectTopTabs,
    },
    meta: {
      layout: 'main',
      isProjectSubRoute: true,
      requiresAuth: true
    },
    beforeEnter(to, from, next) {
      store.commit('setProjectTab', 'automation')
      next();
    },
  },

  // {
  //   path: '/login',
  //   name: 'Login',
  //   component: () => import('@/views/Login'),
  //   beforeEnter(to, from, next) {
  //     //window.location.replace("./login");
  //     next();
  //   },
  //   meta: {
  //     layout: 'login'
  //   }
  // },
  // {
  //   path: '/forgot_pass',
  //   name: 'forgot_pass',
  //   component: () => import('@/views/ForgotPass/ForgotPass.vue'),
  //   meta: {
  //     layout: 'login'
  //   }
  // },
  // {
  //   path: '/register',
  //   name: 'Register',
  //   component: () => import('@/views/Register.vue'),
  //   meta: {
  //     layout: 'login'
  //   }
  // },
  // {
  //   path: '/print',
  //   name: 'Print',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "print" */ '@/views/ThePrint.vue'),
  //   meta: {
  //     layout: 'print'
  //   }
  // },
  // {
  //   path: '/about',
  //   name: 'About',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '@/views/VAbout.vue'),
  //   meta: {
  //     layout: 'about',
  //     //requiresAuth: true
  //   }
  //
  // },
  // {
  //   path: '/vuetest/:subject?',
  //   name: 'Test',
  //   props: (route)=>{
  //     return Object.assign({
  //       subject: route.params.subject || '',
  //     }, getGlobalProps(route))
  //   },
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '@/views/VTest.vue'),
  //   meta: {
  //     layout: 'main',
  //     //requiresAuth: true
  //   }
  //
  // },
  {
    path: '/:pathMatch(.*)*',
    redirect: '/',
    // name: 'rout404',
    // component: NotFound,
    // meta: {
    //   layout: 'main'
    // }
  }
]

//alert('BUILD_DOMAIN_TYPE: ' + process.env.VUE_APP_DOMAIN_TYPE + ' | ' +process.env.VUE_APP_CONTAINER)
// if (process.env.VUE_APP_DOMAIN_TYPE !== 'release') {
//   const BoardViewScreen2 = () => import(/* webpackChunkName: "boardview2" */ '@/views/Project/BoardView/BoardViewScreen/BoardViewScreen2.vue')
//   const SettingsTicketDesignerTest = () => import(/* webpackChunkName: "ticketdesignertest" */ '@/views/Project/Settings/SettingsTicketDesignerTest.vue')
//   const SettingsTicketDesignerTest2 = () => import(/* webpackChunkName: "ticketdesignertest" */ '@/views/Project/Settings/SettingsTicketDesignerTest2.vue')
//   const SettingsTicketDesignerTest3 = () => import(/* webpackChunkName: "ticketdesignertest" */ '@/views/Project/Settings/SettingsTicketDesignerTest3.vue')
//   const DummyScreen = () => import(/* webpackChunkName: "dummy" */ '@/views/Dummy/DummyScreen/DummyScreen.vue')
//
//   routes = [
//     ...routes,
//     {
//       path: '/board2/:projectId?',
//       alias: '/',
//       name: 'Board2',
//       components: {
//         default: BoardViewScreen2,
//         TopTabs: ProjectTopTabs,
//       },
//       meta: {
//         layout: 'main',
//         isProjectSubRoute: true,
//         requiresAuth: true
//       },
//       props: {
//         default: (route)=>{
//           return Object.assign({
//             projectId: route.params.projectId,
//             search: route.query.search || '',
//             searchIn: route.query.searchin || '',
//             requestedById: route.query.requested || '',
//             assignedToId: route.query.assigned || '',
//           }, getGlobalProps(route))
//         },
//         TopTabs: false,
//       },
//       beforeEnter(to, from, next) {
//         store.commit('setProjectTab', 'board');
//         next()
//       }
//     },
//     {
//       path: '/ticket_form_test/:projectId?',
//       name: 'TicketDesignerTest',
//       components: {
//         default: SettingsTicketDesignerTest,
//         TopTabs: ProjectTopTabs,
//       },
//       meta: {
//         layout: 'main',
//         isProjectSubRoute: true,
//         isSettingsSubRoute: true,
//         requiresAuth: true
//       },
//       props: {default: (route)=>{
//           return Object.assign({
//             projectId: route.params.projectId,
//           }, getGlobalProps(route))
//         },
//         TopTabs: false,
//       },
//       beforeEnter(to, from, next) {
//         store.commit('setProjectTab', 'settings')
//         store.commit('setSettingsTab', 'TicketDesignerTest')
//         next()
//       }
//     },
//
//     {
//       path: '/ticket_form_test2/:projectId?',
//       name: 'TicketDesignerTest2',
//       components: {
//         default: SettingsTicketDesignerTest2,
//         TopTabs: ProjectTopTabs,
//       },
//       meta: {
//         layout: 'main',
//         isProjectSubRoute: true,
//         isSettingsSubRoute: true,
//         requiresAuth: true
//       },
//       props: {default: (route)=>{
//           return Object.assign({
//             projectId: route.params.projectId,
//           }, getGlobalProps(route))
//         },
//         TopTabs: false,
//       },
//       beforeEnter(to, from, next) {
//         store.commit('setProjectTab', 'settings')
//         store.commit('setSettingsTab', 'TicketDesignerTest2')
//         next()
//       }
//     },
//
//     {
//       path: '/ticket_form_test3/:projectId?',
//       name: 'TicketDesignerTest3',
//       components: {
//         default: SettingsTicketDesignerTest3,
//         TopTabs: ProjectTopTabs,
//       },
//       meta: {
//         layout: 'main',
//         isProjectSubRoute: true,
//         isSettingsSubRoute: true,
//         requiresAuth: true
//       },
//       props: {default: (route)=>{
//           return Object.assign({
//             projectId: route.params.projectId,
//           }, getGlobalProps(route))
//         },
//         TopTabs: false,
//       },
//       beforeEnter(to, from, next) {
//         store.commit('setProjectTab', 'settings')
//         store.commit('setSettingsTab', 'TicketDesignerTest3')
//         next()
//       }
//     },
//
//     {
//       path: '/dummy/:projectId?',
//       name: 'Dummy',
//       props: {
//         default: (route)=>{
//           return Object.assign({
//           }, getGlobalProps(route))
//         },
//         TopTabs: false,
//       },
//       components: {
//         default: DummyScreen,
//         TopTabs: ProjectTopTabs,
//       },
//       meta: {
//         layout: 'main',
//         isProjectSubRoute: true,
//         requiresAuth: true
//       },
//       beforeEnter(to, from, next) {
//         store.commit('setProjectTab', 'dummy')
//         next();
//       },
//     },
//
//   ]
// }

console.log('router routes', routes);

const router = createRouter({
  history: createWebHistory("/"), // process.env.BASE_URL
  routes,
  linkActiveClass: 'active',
  linkExactActiveClass: 'active'
})
const mainBgColor = getComputedStyle(document.documentElement).getPropertyValue('--main-bg-color').trim();
router.beforeEach((to, from, next) => {
  console.log('router before each', to, from, next);
  if (/*to.name && from.name &&*/ to.name !== from.name) {
    store.dispatch('addLoader', {to: '.main-container', type: 'app-loader', bgColor: mainBgColor})
  }
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (store.getters["auth/isLoggedIn"]) {
      next()
    } else {
      //console.log('Redirect testing 5', './login?' + 'redirect=' + (process.env.NODE_ENV === 'production' ? process.env.VUE_APP_PUBLIC_PATH : "") + to.fullPath)
      //window.location.replace('./login?' + 'redirect=' + (process.env.NODE_ENV === 'production' ? process.env.VUE_APP_PUBLIC_PATH : "") + to.fullPath);
      console.log('Redirect testing 5', '/login?' + 'redirect=' + to.fullPath)
      window.location.replace('/login?' + 'redirect=' + escape(to.fullPath));
    }
  } else {
    next()
  }
})

export default router
