import axios, {AxiosRequestConfig, AxiosResponse} from 'axios'
import {TokenService} from '@/services/storage_service'
import {AuthenticationError} from "@/services/auth_service"
import store from "@/store"
import {IApiResponse, IApiResponseError, IData} from "@/types"
import router from "@/router"

const a = axios.create({
  baseURL: process.env.NODE_ENV === 'production' ? "/" : process.env.VUE_APP_BASE_API_URI,
  // baseURL: process.env.VUE_APP_BASE_URI_EXTERNAL,
  responseType:'json',
  transitional: {
    silentJSONParsing: true,
    forcedJSONParsing: true,
    clarifyTimeoutError: false
  },
  params: {
    type: 'json',
    site_ver: 'v2',
  },
  headers: {
    //"Access-Control-Allow-Origin": "true",
    //"Content-Type": "text/plain"
    //'Content-Type': "multipart/form-data"
  },
  withCredentials: true
})

a.interceptors.request.use(function (config) {
  // console.log('a.interceptors.request', config, JSON.stringify(config))
  // Do something before request is sent
  if (!config.url) {
    config.url = '/action.php'
  }
  // console.log('a.interceptors.request 2', JSON.stringify(config))
  return config
}, function (error) {
  // Do something with request error
  return Promise.reject(error)
})


const ApiService = {

  _axios: a,
  _401interceptor: 0,

  cancelTokens: {},

  _defaultErrorMessage: 'An error ocurred, please try again',

  getBaseURL(): string | undefined {
    return a.defaults.baseURL
  },

  getDefaultParams(): Record<string, unknown> {
    return a.defaults.params
  },

  setCSRF(value: string):void {
    a.defaults.params.c_srf = value
  },

  setTabUid(value: string):void {
    a.defaults.params.tabUid = value
  },

  setDefaultErrorMessage(value: string):void {
    this._defaultErrorMessage = value
  },

  getDefaultErrorMessage():string {
    return this._defaultErrorMessage
  },

  setHeader(): void {
    process.env.VUE_APP_HEADER_TOKEN_KEY && (this._axios.defaults.headers.common[process.env.VUE_APP_HEADER_TOKEN_KEY] = '' + TokenService.getToken())
  },

  removeHeader(): void {
    process.env.VUE_APP_HEADER_TOKEN_KEY && delete this._axios.defaults.headers.common[process.env.VUE_APP_HEADER_TOKEN_KEY]
  },

  processAxiosResponse: function (response: AxiosResponse): Promise<IApiResponse> {
    return new Promise((resolve, reject) => {
      const data = response?.data //Object.assign({}, response?.data);
      if (data && typeof data === 'object' && Object.prototype.hasOwnProperty.call(data, 'isError')) {
        if (data.isError) {
          reject(data)
        } else {
          resolve(data)
        }
      } else {
        console.log('ApiService processAxiosResponse', data, response)
        const networkErrorData: IApiResponseError = {
          isError: true,
          code: 500000,
          message: typeof data?.data === "string" ? data.data : this.getDefaultErrorMessage()
        }
        reject(networkErrorData)
      }
    })
  },

  processApiResponseError: async function (data: IApiResponse, options: IData): Promise<IApiResponseError> {
    //debugger
    console.log('ApiService processApiResponseError', data,  options)
    //const { t } = useI18n()


    const networkErrorData: IApiResponseError = {
      isError: true,
      code: 500001,
      message: this.getDefaultErrorMessage()
    }

    const isServerResponse = data?.isError
    const errorData: IApiResponseError = isServerResponse ? {...data} : networkErrorData

    //alert(errorData.message);
    if (!options.silent) {
      store.dispatch('addNotification', {
        text: errorData.message || this.getDefaultErrorMessage(),
        type: 'error',
      })
    }

    // todo: 'project_info' 'workflow'

    if (errorData?.xdata?.goTabErr === 'board') {
      console.log('goTabErr', errorData?.project_id, store.getters.project?.id, errorData?.project_id || store.getters.project?.id || null)
      await router.push({
        name: 'Board',
        params: {projectId: errorData?.project_id || store.getters.project?.id || null}
      })
    }


    return errorData
  },

  cancelAllRequests() {
    Object.keys(this.cancelTokens).forEach(cancelTokenId => {
      this.cancelRequest(cancelTokenId)
    })
  },

  cancelRequest: function (cancelTokenId: string): void {
    console.log('cancelRequest', cancelTokenId, JSON.stringify(this.cancelTokens))
    if (this.cancelTokens[cancelTokenId]) {
      this.cancelTokens[cancelTokenId].cancel();
      delete this.cancelTokens[cancelTokenId]
    }
  },

  request: function (config: AxiosRequestConfig, cancelTokenId?: string, options = {}): Promise<IApiResponse> {
    const now = performance.now()
    if (cancelTokenId) {
      this.cancelRequest(cancelTokenId)
      this.cancelTokens[cancelTokenId] = axios.CancelToken.source()
      this.cancelTokens[cancelTokenId].time = now
      config.cancelToken = this.cancelTokens[cancelTokenId].token
    }
    console.log('ApiService request', performance.now(), JSON.stringify(Object.keys(this.cancelTokens)))
    return new Promise((resolve, reject) => {
      ApiService._axios(config)
        .then((response: AxiosResponse) => {
          console.log('ApiService request then', performance.now(), response, JSON.stringify(Object.keys(this.cancelTokens)))
          this.processAxiosResponse(response)
            .then((data) => resolve(data))
            .catch(async (data) => reject(await this.processApiResponseError(data, options)))
        })
        .catch(async (error) => {
          console.log('ApiService request catch', error?.response, axios.isCancel(error))
          if(axios.isCancel(error)) {
            reject('cancel')
          } else {
            reject(await this.processApiResponseError(error?.response?.data || error?.response || error, options))
          }
        })
        .finally(() => {
          // emitter.emit('end_loading');
          console.log('ApiService request finally 1', performance.now(), JSON.stringify(Object.keys(this.cancelTokens)), now)
          console.log('ApiService request delete request cancelRequest', cancelTokenId, cancelTokenId && this.cancelTokens[cancelTokenId]?.time, now)
          if (cancelTokenId && this.cancelTokens[cancelTokenId]?.time === now) {
            delete this.cancelTokens[cancelTokenId]
          }
          console.log('ApiService request finally 2', performance.now(), JSON.stringify(Object.keys(this.cancelTokens)))
        })
    })
  },


  mount401Interceptor(): void {

    if (this._401interceptor) {
      this.unmount401Interceptor()
    }
    this._401interceptor = this._axios.interceptors.response.use((response: AxiosResponse) => {
        if (response.status === 204) {
          console.log('Redirect testing 1', response.headers['x-location'], window.location.href)
          window.location.href = response.headers['x-location']
        }
        return response
      },
      (error) => {
        if (error?.request?.status === 401 && error.config && !error.config.__isRetryRequest) {
          if (!error.config?.url?.includes('/authorize')) {
            const e1 = new AuthenticationError(error.errorCode, error.message)
            if (error?.response?.data.message) {
              e1.messages = error.response.data.message
            }
            store.commit("auth/loginError", {errorCode: e1.errorCode, errorMessage: e1.messages})
            console.log('Redirect testing 2', window.location.href)
            store.dispatch('auth/logout', {redirect: window.location.href}).then(() => {
              // console.log('')
            })
          }
          // return
          // if (error.config.url.includes('/authorize')) {
          // Refresh token has failed. Logout the user
          // console.log('!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!');
          // store.dispatch('auth/logout')
          //throw error
          // } else {
          //     const e1 = new AuthenticationError(error.errorCode, error.message);
          //     if (error.response.data.message) {
          //         e1.messages = error.response.data.message;
          //     }
          //     return router.push("/login");
          //return Promise.reject(error);
          //throw error;
          // return store.dispatch('auth/refreshToken').then(() => {
          //     // Retry the original request
          //     error.config.__isRetryRequest = true;
          //     error.config.headers.Authorization = TokenService.getToken();
          //     return this._axios(error.config);
          // }).catch(error => {
          //     router.push("/login");
          //     throw error;
          // })
          // }
        }
        // If error was not 401 just reject as is
        return Promise.reject(error)
      }
    )
  },

  unmount401Interceptor(): void {
    this._axios.interceptors.response.eject(this._401interceptor)
  }

}

export default ApiService
